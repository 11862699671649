const Menu = [
    {
        heading: 'Main',
        translate: 'sidebar.heading.HEADER'
    },
    {
        name: 'Home',
        icon: 'icon-speedometer',
        translate: 'sidebar.nav.Home',
        label: { value: 2, color: 'success', icon: 'fas fa-plus' },
        submenu: [{
            name: 'Home Page',
            path: '/UDVCTMSHomePage'
        },
        {
            name: 'User Profile',
            path: '/UDVCTMSUserProfile'
        }
        ]
    },
    {
        name: 'Transport',
        icon: 'fas fa-car',
        translate: 'sidebar.nav.Transport',
        label: { value: 8, color: 'success', icon: 'fas fa-plus' },
        submenu: [{
            name: 'Record Arrive',
            path: '/Record_Arrive'
        },
        {
            name: 'Truck Mapping',
            path: '/Truck_Mapping'
        },
        {
            name: 'Confirm Loading',
            path: '/Confirm_Loading'//Confirm_Transport
        },
        /*{
            name: 'Mobile Confirm Transport',
            path: '/Mobile_Confirm_Transport'
        },*/
        {
            name: 'Start Shipping',
            path: '/Confirm_Shipping'//Confirm_Transport_Out
        },
        /*{
            name: 'Mobile Confirm Transport Out',
            path: '/Mobile_Confirm_Transport_Out'
        },*/
        {
            name: 'Customer Confirm',
            path: '/Customer_Confirm'
        },
        /*{
            name: 'Mobile Customer Confirm',
            path: '/Mobile_Customer_Confirm'
        },*/
        {
            name: 'Transport Finish',
            path: '/Transport_Finish'
        },
        /*{
            name: 'Truck Monitor',
            path: '/Truck_Monitor'
        },*/
        {
            name: 'Dashboard',
            path: '/Dashboard'
        },
            /*{
                name: 'Truck Mapping History',
                path: '/Truck_Mapping_History'
            },*/
        ]
    },
    /*{
        name: 'Customer',
        icon: 'fas fa-bus',
        translate: 'sidebar.nav.Customer',
        label: { value: 1, color: 'success' },
        submenu: [{
            name: 'Customer Confirm',
            path: '/Customer_Confirm'
        }
        ]
    },*/
    {
        name: 'Monitor',
        icon: 'fas fa-chalkboard',
        translate: 'sidebar.nav.Monitor',
        label: { value: 1, color: 'success', icon: 'fas fa-plus' },
        submenu: [{
            name: 'Shipping Report',
            path: '/Picking_List'
        }/*,
        {
            name: 'Picking List History',
            path: '/Picking_List_History'
        }*/
        ]
    },
    {
        name: 'History',
        icon: 'fas fa-chalkboard',
        translate: 'sidebar.nav.History',
        label: { value: 5, color: 'success', icon: 'fas fa-plus' },
        submenu: [{
            name: 'Shipping Report',
            path: '/Picking_List'
        },
        {
            name: 'Dashboard',
            path: '/Dashboard'
        },
        {
            name: 'Dashboard Truck',
            path: '/Dashboard_Truck'
        },
        {
            name: 'Truck Mapping History',
            path: '/Truck_Mapping_History'
        },
        {
            name: 'Truck History',
            path: '/Truck_History'
        }/*,
        {
            name: 'Picking List History',
            path: '/Picking_List_History'
        }*/
        ]
    },





    /*{
        name: 'Dynamics Menu',
        icon: 'icon-grid',
        path: '/widgets',
        label: { value: 0, color: 'success' },
        translate: 'sidebar.nav.DynamicsMenu'
    },*/
    {
        name: 'Master',
        icon: 'icon-grid',
        path: '/widgets',
        label: { value: 0, color: 'success', icon: 'fas fa-plus' },
        translate: 'sidebar.nav.Master'
    },
    {
        name: 'Admin',
        icon: 'icon-chemistry',
        label: { value: 5, color: 'success', icon: 'fas fa-plus' },
        translate: 'Admin.Title',
        submenu: [{
            name: 'Workflows_Domain_Menu',
            path: '/Workflows_Domain',
            translate: 'Admin.Workflows_Domain.Title'
        }, {
            name: 'Workflows_Master_Menu',
            path: '/Workflows_Master',
            translate: 'Admin.Workflows_Master.Title'
        }, {
            name: 'Workflows_Flow_Menu',
            path: '/Workflows_Flow',
            translate: 'Admin.Workflows_Flow.Title'
        }, {
            name: 'Workflows_Flow_Fields_Menu',
            path: '/Workflows_Flow_Fields',
            translate: 'Admin.Workflows_Flow_Fields.Title'
        }, {
            name: 'Workflows_Flow_Mapping_Menu',
            path: '/Workflows_Flow_Mapping',
            translate: 'Admin.Workflows_Flow_Mapping.Title'
        }, {
            name: 'Process_Master',
            path: '/Process_Master',
            translate: 'Admin.Process_Master.Title'
        }
        ]
    },
    {
        name: 'Pages',
        icon: 'icon-doc',
        label: { value: 7, color: 'success', icon: 'fas fa-plus' },
        translate: 'sidebar.nav.pages.PAGES',
        submenu: [{
            name: 'Google',
            path: '/map-google',
            translate: 'sidebar.nav.map.GOOGLE'
        }, {
            name: 'Login',
            path: '/login',
            translate: 'sidebar.nav.pages.LOGIN'
        },
        {
            name: 'Register',
            path: '/register',
            translate: 'sidebar.nav.pages.REGISTER'
        },
        {
            name: 'Recover',
            path: '/recover',
            translate: 'sidebar.nav.pages.RECOVER'
        },
        {
            name: 'Lock',
            path: '/lock',
            translate: 'sidebar.nav.pages.LOCK'
        },
        {
            name: 'Not Found',
            path: '/notfound',
        },
        {
            name: 'Error 500',
            path: '/error500'
        },
        {
            name: 'Maintenance',
            path: '/maintenance'
        }
        ]
    }







    /*, {
        heading: 'Dashboard Main',
        translate: 'sidebar.heading.HEADER'
    },
    {
        name: 'Dashboard',
        icon: 'icon-speedometer',
        translate: 'sidebar.nav.DASHBOARD',
        label: { value: 3, color: 'success' },
        submenu: [{
            name: 'Dashboard v1',
            path: '/dashboardv1'
        },
        {
            name: 'Dashboard v2',
            path: '/dashboardv2'
        },
        {
            name: 'Dashboard v3',
            path: '/dashboardv3'
        }
        ]
    },
    {
        name: 'Widgets',
        icon: 'icon-grid',
        path: '/widgets',
        label: { value: 30, color: 'success' },
        translate: 'sidebar.nav.WIDGETS'
    },
    {
        heading: 'Components',
        translate: 'sidebar.heading.COMPONENTS'
    },
    {
        name: 'Elements',
        icon: 'icon-chemistry',
        translate: 'sidebar.nav.element.ELEMENTS',
        submenu: [{
            name: 'Buttons',
            path: '/buttons',
            translate: 'sidebar.nav.element.BUTTON'
        },
        {
            name: 'Notifications',
            path: '/notifications',
            translate: 'sidebar.nav.element.NOTIFICATION'
        },
        {
            name: 'Sweetalert',
            path: '/sweetalert'
        },
        {
            name: 'Carousel',
            path: '/carousel',
            translate: 'sidebar.nav.element.INTERACTION'
        },
        {
            name: 'Spinners',
            path: '/spinners',
            translate: 'sidebar.nav.element.SPINNER'
        },
        {
            name: 'Dropdown',
            path: '/dropdown',
            translate: 'sidebar.nav.element.DROPDOWN'
        },
        {
            name: 'Nestable',
            path: '/nestable'
        },
        {
            name: 'Sortable',
            path: '/sortable'
        },
        {
            name: 'Cards',
            path: '/cards',
            translate: 'sidebar.nav.element.CARD'
        },
        {
            name: 'Grid',
            path: '/grid',
            translate: 'sidebar.nav.element.GRID'
        },
        {
            name: 'Grid Masonry',
            path: '/grid-masonry',
            translate: 'sidebar.nav.element.GRID_MASONRY'
        },
        {
            name: 'Typography',
            path: '/typography',
            translate: 'sidebar.nav.element.TYPO'
        },
        {
            name: 'IconsFont',
            path: '/icons-font',
            translate: 'sidebar.nav.element.FONT_ICON',
            label: { value: '+400', color: 'success' }
        },
        {
            name: 'IconsWeather',
            path: '/icons-weather',
            translate: 'sidebar.nav.element.WEATHER_ICON',
            label: { value: '+100', color: 'success' }
        },
        {
            name: 'Colors',
            path: '/colors',
            translate: 'sidebar.nav.element.COLOR'
        },
        {
            name: 'Modal',
            path: '/modal',
            translate: 'sidebar.nav.element.MODAL'
        }
        ]
    },
    {
        name: 'Forms',
        icon: 'icon-note',
        translate: 'sidebar.nav.form.FORM',
        submenu: [{
            name: 'Standard',
            path: '/form-standard',
            translate: 'sidebar.nav.form.STANDARD'
        },
        {
            name: 'Extended',
            path: '/form-extended',
            translate: 'sidebar.nav.form.EXTENDED'
        },
        {
            name: 'Validation',
            path: '/form-validation',
            translate: 'sidebar.nav.form.VALIDATION'
        },
        {
            name: 'Wizard',
            path: '/form-wizard',
        },
        {
            name: 'Upload',
            path: '/form-upload',
        },
        {
            name: 'Cropper',
            path: '/form-cropper',
        }
        ]
    },
    {
        name: 'Charts',
        icon: 'icon-graph',
        translate: 'sidebar.nav.chart.CHART',
        submenu: [{
            name: 'Flot',
            path: '/chart-flot',
            translate: 'sidebar.nav.chart.FLOT'
        },
        {
            name: 'Radial',
            path: '/chart-radial',
            translate: 'sidebar.nav.chart.RADIAL'
        },
        {
            name: 'Chartjs',
            path: '/chart-chartjs',
        },
        {
            name: 'Morris',
            path: '/chart-morris',
        },
        {
            name: 'Chartist',
            path: '/chart-chartist',
        }
        ]
    },
    {
        name: 'Tables',
        icon: 'icon-grid',
        translate: 'sidebar.nav.table.TABLE',
        submenu: [{
            name: 'Standard',
            path: '/table-standard',
            translate: 'sidebar.nav.table.STANDARD'
        },
        {
            name: 'Extended',
            path: '/table-extended',
            translate: 'sidebar.nav.table.EXTENDED'
        },
        {
            name: 'Datatable',
            path: '/table-datatable',
            translate: 'sidebar.nav.table.DATATABLE'
        },
        {
            name: 'Datagrid',
            path: '/table-datagrid',
        }
        ]
    },
    {
        name: 'Maps',
        icon: 'icon-map',
        translate: 'idebar.nav.map.MAP',
        submenu: [{
            name: 'Google',
            path: '/map-google',
            translate: 'sidebar.nav.map.GOOGLE'
        },
        {
            name: 'Vector',
            path: '/map-vector',
            translate: 'sidebar.nav.map.VECTOR'
        }
        ]
    },
    {
        heading: 'More',
        translate: 'sidebar.heading.MORE'
    },
    {
        name: 'Extras',
        icon: 'icon-cup',
        translate: 'sidebar.nav.extra.EXTRA',
        submenu: [{
            name: 'Mailbox',
            path: '/mailbox',
            translate: 'sidebar.nav.extra.MAILBOX',
        },
        {
            name: 'Bug Tracker',
            path: '/bug-tracker'
        },
        {
            name: 'Contact Details',
            path: '/contact-details'
        },
        {
            name: 'Contacts',
            path: '/contacts'
        },
        {
            name: 'Faq',
            path: '/faq'
        },
        {
            name: 'File Manager',
            path: '/file-manager'
        },
        {
            name: 'Followers',
            path: '/followers'
        },
        {
            name: 'Help Center',
            path: '/help-center'
        },
        {
            name: 'Plans',
            path: '/plans'
        },
        {
            name: 'Project Details',
            path: '/project-details'
        },
        {
            name: 'Projects',
            path: '/projects'
        },
        {
            name: 'Settings',
            path: '/settings'
        },
        {
            name: 'Social Board',
            path: '/social-board'
        },
        {
            name: 'Team Viewer',
            path: '/team-viewer'
        },
        {
            name: 'Vote Links',
            path: '/vote-links'
        },
        {
            name: 'Timeline',
            path: '/timeline',
            translate: 'sidebar.nav.extra.TIMELINE'
        },
        {
            name: 'Calendar',
            path: '/calendar',
            translate: 'sidebar.nav.extra.CALENDAR'
        },
        {
            name: 'Invoice',
            path: '/invoice',
            translate: 'sidebar.nav.extra.INVOICE'
        },
        {
            name: 'Search',
            path: '/search',
            translate: 'sidebar.nav.extra.SEARCH'
        },
        {
            name: 'Todo',
            path: '/todo',
            translate: 'sidebar.nav.extra.TODO'
        },
        {
            name: 'Profile',
            path: '/profile',
            translate: 'sidebar.nav.extra.PROFILE'
        }
        ]
    },
    {
        name: 'Blog',
        icon: 'icon-notebook',
        submenu: [{
            name: 'List',
            path: '/blog-list'
        },
        {
            name: 'Post',
            path: '/blog-post'
        },
        {
            name: 'Articles',
            path: '/blog-articles'
        },
        {
            name: 'Article View',
            path: '/blog-article-view'
        }
        ]
    },
    {
        name: 'eCommerce',
        icon: 'icon-basket-loaded',
        submenu: [{
            name: 'Orders',
            path: '/ecommerce-orders',
            label: { value: 10, color: 'success' },
        },
        {
            name: 'Order-view',
            path: '/ecommerce-order-view'
        },
        {
            name: 'Products',
            path: '/ecommerce-products'
        },
        {
            name: 'Product-view',
            path: '/ecommerce-product-view'
        },
        {
            name: 'Checkout',
            path: '/ecommerce-checkout'
        }
        ]
    },
    {
        name: 'Forum',
        icon: 'icon-speech',
        path: '/forum'
    }*/
];

export default Menu;