/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.8
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from "react-dom";

// App Routes
import Routes from './Routes';
import DynamicsRoutes from './DynamicsRoutes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss';

import APPCONFIG from '../src/constants/appConfig';


class App extends Component {
  render() {
    document.title = APPCONFIG.ProjectTitleName;
    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

    /*return (<React.StrictMode>
      <Routes />
    </React.StrictMode>,
      document.getElementById("root"));*/
    return (
      /*<BrowserRouter basename={basename}>
         <Routes />
      </BrowserRouter>*/
      <BrowserRouter basename={basename}>
        {
          APPCONFIG.ProjectName == "UDVCTMS" ?
            <Routes />//UDVCTMS
            :
            <DynamicsRoutes />//Default
        }
      </BrowserRouter>
      /*<BrowserRouter basename={basename}>
        <Routes />
      </BrowserRouter>*/
    );

  }
}

export default App;
