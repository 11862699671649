import APPCONFIG from '../constants/appConfig';
const axios = require('axios');
const url = require('url');
const CryptoJS = require('crypto-js');
const keyValue = 'okpahklf.l';
const ivValue = 'okpahk';
class Encryption {
    async Encrypt(clearText, key) {
        const postValue = {
            UserName: key,
            Password: clearText
        };
        let encryptedString = "";
        let searchurl = APPCONFIG.apiurlLogin + "/Encrypt?result=" + JSON.stringify(postValue);
        try {
            const resp = await axios.get(searchurl);
            const objValue = JSON.parse(resp.data);
            if (objValue.Complete) {
                encryptedString = objValue.Value;
            }
        } catch (err) {
            console.log(err);
        }
        return encryptedString;
    }
    static EncryptURL(value) {
        return CryptoJS.AES.encrypt(JSON.stringify(value), keyValue).toString();
        //return CryptoJS.AES.encrypt(value, keyValue).toString();
    }
    static DecodeURL(value) {
        return JSON.parse(CryptoJS.AES.decrypt(value.replace('?', ''), keyValue).toString(CryptoJS.enc.Utf8));
        //return CryptoJS.AES.decrypt(value.replace('?', ''), keyValue).toString(CryptoJS.enc.Utf8);
    }
    static JsonValueData(UserName
        , Password
        , SelectDynamicsFlowDomainNo
        , FName
        , IsAdmin
        , JsonValueData) {
        const valueData = {
            "Name": UserName,
            "Pass": Password,
            "DNo": SelectDynamicsFlowDomainNo,
            "FName": FName,
            "IsAdmin": IsAdmin,
            "VData": JsonValueData,
        };
        return JSON.stringify(valueData);
    }
    static JsonFlowValueData(UserName
        , Password
        , SelectDynamicsFlowDomainNo
        , SelectDynamicsFlowGroupNo
        , SelectDynamicsFlowNo
        , FName
        , IsAdmin
        , JsonValueData) {
        const valueData = {
            "Name": UserName,
            "Pass": Password,
            "DNo": SelectDynamicsFlowDomainNo,
            "GNo": SelectDynamicsFlowGroupNo,
            "FNo": SelectDynamicsFlowNo,
            "FName": FName,
            "IsAdmin": IsAdmin,
            "VData": JsonValueData
        };
        return JSON.stringify(valueData);
    }
    static JsonDynamicsValueData(UserName
        , Password
        , SelectDynamicsFlowDomainNo
        , FName
        , IsAdmin
        , CurrentLanguage
        , CurrentDateFormat
        , CurrentTimeFormat
        , CurrentTheme
        , CurrentGridPageSize
        , UserGroupNo
        , JsonValueData) {
        const valueData = {
            "Name": UserName,
            "Pass": Password,
            "DNo": SelectDynamicsFlowDomainNo,
            "FName": FName,
            "IsAdmin": IsAdmin,
            "Lang": CurrentLanguage,
            "DF": CurrentDateFormat,
            "TF": CurrentTimeFormat,
            "Theme": CurrentTheme,
            "PS": CurrentGridPageSize,
            "UG": UserGroupNo,
            "VData": JsonValueData,
        };
        const searchPost = {
            "CurrentUser": valueData
        };
        return JSON.stringify(searchPost);
    }
    static JsonDynamicsValueData2(UserName
        , Password
        , SelectDynamicsFlowDomainNo
        , FName
        , IsAdmin
        , CurrentLanguage
        , CurrentDateFormat
        , CurrentTimeFormat
        , CurrentTheme
        , CurrentGridPageSize
        , UserGroupNo
        , JsonValueData) {
        const valueData = {
            "Name": UserName,
            "Pass": Password,
            "DNo": SelectDynamicsFlowDomainNo,
            "FName": FName,
            "IsAdmin": IsAdmin,
            "Lang": CurrentLanguage,
            "DF": CurrentDateFormat,
            "TF": CurrentTimeFormat,
            "Theme": CurrentTheme,
            "PS": CurrentGridPageSize,
            "UG": UserGroupNo,
            "VData": JsonValueData,
        };
        return JSON.stringify(valueData);
    }
}

export default Encryption;