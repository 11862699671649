const APPCONFIG = {
  brand: 'Reatltime Development',
  user: 'Realtime Develoment',
  year: '2019',
  version: 'V Next-2021.10.22-19.05',
  ProjectTitleName: "Dynamics 202208 : Dynamics vNext",


  /*//localhost
  apiurl:
    'http://localhost:9068/',
  apiurlLogin:
    'http://localhost/ICSSite/DWTPIApi',
  googleMapsApiKey: "AIzaSyDGArUEBa5ns09IA7nt7jP-xfNIUkToFts",*/


  //host
  apiurl:
    'https://vnextapi.realtime.co.th/',
  apiurlLogin:
    'https://aru.realtime.co.th/dynamics/DWTPIAPI',
  googleMapsApiKey: "AIzaSyDGArUEBa5ns09IA7nt7jP-xfNIUkToFts",


  /*//udvc
  apiurl:
    'http://172.16.50.229:3002/',
  apiurlLogin:
    'https://aru.realtime.co.th/dynamics/DWTPIAPI',
  googleMapsApiKey: "AIzaSyDGArUEBa5ns09IA7nt7jP-xfNIUkToFts",*/


  /*databaseurl:
    'https://vapi.realtime.co.th/api/use/dynamics_bscb/collection/',
  dbName: '',
  ProjectName: "dynamics_bscb",*/

  /*databaseurl:
    'https://vapi.realtime.co.th/api/use/dynamics_202208/collection/',
  dbName: '',
  ProjectName: "dynamics_202208",*/

  //host
  databaseurl:
    'https://vapi.realtime.co.th/api/use/dynamics_crru_202204/collection/',
  dbName: '',
  ProjectName: "UDVCTMS",


  /*//udvc
  databaseurl:
    'http://172.16.50.229:3000/api/use/dynamics_crru_202204/collection/',
  dbName: '',
  ProjectName: "UDVCTMS",*/






  //localhost 2
  /*apiurl:
    'http://localhost:9068/',
  apiurlLogin: '',
  googleMapsApiKey: "AIzaSyBNs42Rt_CyxAqdbIBK0a5Ut83QiauESPA",
  databaseurl: '',
  //apiurl: 'https://vapi.realtime.co.th/api/',
  dbName: 'dynamics_202208',
  ProjectName: "dynamics_202208",*/

  //host
  /*apiurl:
    'https://vnextapi.realtime.co.th/',
  apiurlLogin:
    'https://aru.realtime.co.th/dynamics/DWTPIAPI',
  //googleMapsApiKey: "AIzaSyBNs42Rt_CyxAqdbIBK0a5Ut83QiauESPA",
  googleMapsApiKey: "AIzaSyA-L1c93eoDvHKYAFq6_haSL62BewHhbo0",
  databaseurl:
    'https://vapi.realtime.co.th/api/use/dynamics_crru_202204/collection/',
  dbName: '',
  ProjectName: "UDVCTMS",*/

  //udvc
  /*apiurl:
    'http://172.16.51.92:3002/',
  apiurlLogin:
    'https://aru.realtime.co.th/dynamics/DWTPIAPI',
  googleMapsApiKey: "AIzaSyA-L1c93eoDvHKYAFq6_haSL62BewHhbo0",
  databaseurl:
    'http://172.16.51.92:3000/api/use/dynamics_crru_202204/collection/',
  dbName: '',
  ProjectName: "UDVCTMS",*/



  /*'https://vnextapi.realtime.co.th/',*/

  /*'http://localhost/ICSSite/DWTPIApi',*/
  /*'https://vnextapi.realtime.co.th/',*/
  /*,*/

  /*,*/


  settings: {
    name: 'bampro prakit',
    lastname: "xxx"
  },



};

export default APPCONFIG;
