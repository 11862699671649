import React from 'react';

import Header from './Header';
import Sidebar from './Sidebar';
import DynamicsSidebar from './DynamicsSidebar';
import Offsidebar from './Offsidebar';
import Footer from './Footer';

import APPCONFIG from '../../../src/constants/appConfig';

const Base = props => (
    <div className="wrapper">
        <Header />

        {
            APPCONFIG.ProjectName == "UDVCTMS" ?
                <Sidebar />
                :
                <DynamicsSidebar />
        }

        <Offsidebar />

        <section className="section-container">
            {props.children}
        </section>

        <Footer />
    </div>
);

export default Base;
