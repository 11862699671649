const DynamicsMenu = [
    {
        heading: 'Main',
        hearder: true,
        translate: 'sidebar.heading.HEADER'
    },
    {
        name: 'Home',
        icon: 'icon-speedometer',
        translate: 'sidebar.nav.Home',
        label: { value: 2, color: 'success', icon: 'fas fa-plus' },
        hearder: true,
        menutype: '99',
        submenu: [{
            name: 'Home Page',
            path: '/DynamicsHomePage',
            key: '901',
            hearder: false,
            menutype: '99'
        },
        {
            name: 'User Profile',
            path: '/DynamicsUserProfile',
            key: '902',
            hearder: false,
            menutype: '99'
        }
        ]
    },
    {
        name: 'Flow',
        key: '100',
        hearder: true,
        menutype: '1'
    },
    {
        name: 'Library',
        key: '700',
        hearder: true,
        menutype: '2'
    },
    {
        name: 'Admin',
        key: '800',
        hearder: true,
        menutype: '0'
    }
    /*{
        name: 'Admin',
        icon: 'icon-chemistry',
        label: { value: 5, color: 'success', icon: 'fas fa-plus' },
        translate: 'Admin.Title',
        submenu: [{
            name: 'AdminHome',
            path: '/AdminHome',
            translate: 'Admin.AdminHome.Title'
        },
        {
            name: 'Dynamics 20220811',
            path: '/Dynamics20220811',
            translate: 'Admin.Dynamics20220811.Title'
        }
        ]
    },*/
];

export default DynamicsMenu;